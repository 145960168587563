@import "~@shopify/polaris-tokens/dist/index";
/* 警告横幅 */
.Polaris-Icon--colorWarning svg {
  fill: $color-yellow !important;
}
/* 列表子项 */
.Polaris-ResourceItem--newDesignLanguage.Polaris-ResourceItem--selected {
  background-color: $color-teal-lighter !important;
}
.Polaris-ResourceItem--newDesignLanguage:hover {
  background-color: $color-teal-lighter !important;
}
/* 筛选器的按钮 */
.Polaris-Button.Polaris-Button--newDesignLanguage::after {
  border: 0.1rem solid $color-ink-lightest !important;
  top: 0 !important;
  left: 0 !important;
  right: 0 !important;
  bottom: 0 !important;
}
/* 标签 */
.Polaris-Tag {
  background-color: $color-sky !important;
}
.title {
  color: $color-green-dark;
}
.card {
  height: 400px;
}
